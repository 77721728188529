export const setCrispUserEmail = (email) => {
    if (window && window.$crisp) {
        window.$crisp.push(["set", "user:email", [email]]);
    }
}

export const sendChatMessage = (message) => {
    if (window && window.$crisp) {
        window.$crisp.push(["do", "message:show", ["text", message]]);
    }
}

export const firstChartMessage = () => {
    const message1 = 'Hey there! Im Alex the founder of Nochart. Congrats on setting up your first Notion chart 🎉'
    const message2 = "A small gift to congratulate you? 🎁 I'd be happy to offer you a 40% discount on your first month of subscription. Let me know if you're interested!"

    sendChatMessage(message1);
}