import { useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import '@/assets/main.css';
import '@/assets/chrome-bug.css';

import Layout from '@/components/Layout';
import { UserContextProvider } from '@/utils/useUser';
import 'nprogress/nprogress.css';
import Script from 'next/script';
import { usePostHog } from 'next-use-posthog';

const TopProgressBar = dynamic(
  () => {
    return import('components/ui/TopProgressBar');
  },
  { ssr: false }
);

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  usePostHog('phc_HrkjihcRzPzIjdsBarjV4q74Q9XyLInKM80R4yUx0n0', {
    api_host: 'https://app.posthog.com',
    loaded: (posthog) => {
      if (
        process.env.NODE_ENV !== 'development' &&
        router.pathname !== '/graph'
      ) {
        posthog.opt_in_capturing;
      } else posthog.opt_out_capturing;
    }
  });
  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  if (router.pathname === '/graph') {
    return (
      <div>
        <Script src="https://www.googletagmanager.com/gtag/js?id=G-H6G0S27MMC" />
        <Script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-H6G0S27MMC');`}
        </Script>
        <Component {...pageProps} />
      </div>
    );
  }
  if (/^\/analytics\/.*/.test(router.pathname)) {
    return <Component {...pageProps} />;
  }

  return (
    <div className="bg-primary">
      <UserContextProvider>
        <TopProgressBar />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </UserContextProvider>
    </div>
  );
}
export default MyApp;
