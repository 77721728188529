import { useEffect, useState } from 'react';
import Script from 'next/script';
import { useUser } from '@/utils/useUser';

export const CookieConsent = () => {
  const { user } = useUser();
  const [consent, setConsent] = useState(undefined);
  useEffect(() => {
    const userConsent = window.localStorage?.getItem('userConsent');
    console.log(userConsent);
    if (userConsent !== null) setConsent(userConsent);
    else setConsent('unset');
  }, []);

  const clickCallback = (value) => {
    setConsent(value);
    if (window) window.localStorage?.setItem('userConsent', value);
  };
  return (
    <div className="relative">
      {consent === 'unset' && (
        <div className="fixed z-50  bottom-0 md:left-8 md:bottom-8 border-2 rounded-md shadow flex flex-col bg-white p-4 w-full sm:w-96 ">
          <p className="text-xl mb-2 font-semibold">Your privacy 🍪</p>
          <p>
            We use cookies to provide a better experience for our users. Is that
            OK with you?
          </p>
          <div className="flex mt-2">
            <button
              className="w-full border rounded-md h-8 text-accents-7"
              onClick={() => clickCallback('false')}
            >
              No way
            </button>
            <button
              className="w-full border rounded-md h-8 "
              onClick={() => clickCallback('true')}
            >
              Sure!
            </button>
          </div>
        </div>
      )}

      {consent !== 'false' && <Scripts user={user} />}
    </div>
  );
};

const Scripts = ({ user }) => (
  <>
    <Script strategy="lazyOnload">
      {`window.$crisp=[];window.CRISP_WEBSITE_ID="1b889f59-c84f-491f-a70c-bff8d8991e5f";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
    </Script>
    <Script src="https://www.googletagmanager.com/gtag/js?id=G-H6G0S27MMC" />
    <Script>
      {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-H6G0S27MMC');`}
    </Script>
    {user && (
      <Script>
        {`(function() {
    window.sib = {
        equeue: [],
        client_key: "uxvrpr457zprexcu31ion1tu"
    };
    /* OPTIONAL: email for identify request*/
    window.sib.email_id = '${user.email}';
    window.sendinblue = {};
    for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
    (function(k) {
        window.sendinblue[k] = function() {
            var arg = Array.prototype.slice.call(arguments);
            (window.sib[k] || function() {
                    var t = {};
                    t[k] = arg;
                    window.sib.equeue.push(t);
                })(arg[0], arg[1], arg[2]);
            };
        })(j[i]);
    }
    var n = document.createElement("script"),
        i = document.getElementsByTagName("script")[0];
    n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
})();`}
      </Script>
    )}
  </>
);
