// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://b007586a52594d8c822135af6cdb426c@o1209081.ingest.sentry.io/6342468',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  integrations: [new posthog.SentryIntegration(posthog, 'nochart', 'nochart')]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
