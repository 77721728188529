import { useEffect, useState, createContext, useContext } from 'react';
import { setCrispUserEmail } from './crisp';
import {
  loginEvent,
  setpostHogUserEmail,
  setUserID,
  setUserProperties,
  signUpEvent
} from './ga';

import {
  hasReachedLimit,
  priceIDToPlan,
  priceIDToPrice,
  supabase
} from './supabase-client';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [session, setSession] = useState(undefined);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userNotionTokens, setUserNotionTokens] = useState(null);
  const [userGraphs, setUserGraphs] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [reachedLimit, setReachedLimit] = useState(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setSession(session);
    setUser(session?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  const getUserDetails = () => supabase.from('users').select('*').single();
  const getUserTokens = (userID) =>
    supabase.from('notion_tokens').select('*').eq('user_id', userID);
  const getUserGraphs = (userID) =>
    supabase.from('graphs').select('*').eq('user_id', userID);
  const getSubscription = () =>
    supabase
      .from('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single();

  useEffect(() => {
    if (user) {
      Promise.allSettled([
        getUserDetails(),
        getUserTokens(user.id),
        getSubscription(),
        getUserGraphs(user.id)
      ]).then((results) => {
        setUserDetails(results[0].value.data);
        setUserNotionTokens(results[1].value.data);
        setSubscription(results[2].value.data);
        setUserGraphs(results[3].value.data);
        setUserLoaded(true);
      });
      setUserID(user.id);
      setTimeout(() => {
        setCrispUserEmail(user.email);
        setpostHogUserEmail(user.email);
      }, 2000);
    }
  }, [user]);
  useEffect(() => {
    if (subscription && subscription.price_id) {
      setUserProperties({
        price_id: subscription.price_id,
        MRR: priceIDToPrice[subscription.price_id],
        Plan: priceIDToPlan[subscription.price_id]
      });
    }
  }, [subscription]);
  useEffect(() => {
    setReachedLimit(hasReachedLimit(userGraphs, subscription?.price_id));
  }, [userGraphs, subscription]);
  const refreshUserDetails = async () => {
    setUserLoaded(false);
    const res = await getUserDetails(user.id);
    setUserDetails(res.data);
    setUserLoaded(true);
  };
  const refreshGraphs = async () => {
    const res = await getUserGraphs(user.id);
    setUserGraphs(res.data);
  };

  const value = {
    session,
    user,
    userDetails,
    userNotionTokens,
    userGraphs,
    userLoaded,
    subscription,
    reachedLimit,
    refreshGraphs,
    refreshUserDetails,
    signIn: (options) => {
      loginEvent();
      return supabase.auth.signIn(options);
    },
    signUp: (options) => {
      signUpEvent();
      return supabase.auth.signUp(options);
    },
    signOut: () => {
      setUserDetails(null);
      setSubscription(null);
      return supabase.auth.signOut();
    },
    sendResetPasswordEmail: ({ email }) => {
      return supabase.auth.api.resetPasswordForEmail(email);
    },
    resetPassword: ({ access_token, newPassword }) => {
      return supabase.auth.api.updateUser(access_token, {
        password: newPassword
      });
    }
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
