import { priceIDToPlan, priceIDToPrice } from './supabase-client';
import posthog from 'posthog-js';

export const setUserID = (id) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('set', 'user_id', id);
  }
  posthog.identify('[id]');
};
export const setUserProperties = (properties) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('set', 'user_properties', {
        ...properties
      });
  }
  posthog.capture('user_properties', { $set: { userProperty: 'value' } });
};
export const setpostHogUserEmail = (email) => {
  posthog.people.set({ email });
};
export const signUpEvent = () => {
  posthog.capture('sign_up');
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('event', 'sign_up', {});
  }
};
export const loginEvent = () => {
  posthog.capture('login');
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('event', 'login', {});
  } else {
    setTimeout(() => {
      if (window) {
        const gtag = window.gtag;
        if (gtag) gtag('event', 'login', {});
      }
    }, 1000);
  }
};
export const purchaseEvent = (subscription) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'purchase', {
        currency: 'usd',
        transaction_id: subscription.id,
        value: priceIDToPrice[subscription.price_id],
        items: {
          item_id: subscription.price_id,
          item_name: priceIDToPlan[subscription.price_id]
        }
      });
    posthog.capture('purchase', {
      currency: 'usd',
      transaction_id: subscription.id,
      value: priceIDToPrice[subscription.price_id],
      items: {
        item_id: subscription.price_id,
        item_name: priceIDToPlan[subscription.price_id]
      }
    });
    const sendinblue = window.sendinblue;
    if (sendinblue)
      sendinblue.track('purchase', {
        id: subscription.price_id,
        plan: priceIDToPlan[subscription.price_id],
        value: priceIDToPrice[subscription.price_id]
      });
  }
};
export const beginCheckoutEvent = (price) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'begin_checkout', {
        currency: 'usd',
        value:
          price.interval === 'year'
            ? price.unit_amount / (100 * 12)
            : price.unit_amount / 100,
        items: {
          item_id: price.id,
          item_name: priceIDToPlan[price.id]
        }
      });
  }
};
export const viewChartEvent = (id) => {
  posthog.capture('view_chart', {
    id
  });
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'view_chart', {
        id
      });
  }
};
export const clickNewChartEvent = (id) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'click_new_chart', {
        id: id
      });
  }
  posthog.capture('click_new_chart');
};
export const openNotionWindowEvent = () => {
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('event', 'open_notion_window', {});
  }
  posthog.capture('open_notion_window');
};
export const connectedToNotionEvent = () => {
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('event', 'connected_to_notion', {});
  }
  posthog.capture('connected_to_notion');
};
export const EmptyDBListEvent = () => {
  if (window) {
    const gtag = window.gtag;
    if (gtag) gtag('event', 'empty_db_list', {});
  }
  posthog.capture('empty_db_list');
};

export const NotionIndexNoticeEvent = (id) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'view_indexing_issue_notice', {
        id: id
      });
  }
  posthog.capture('view_indexing_issue_notice');
};

export const newChartEvent = (id, number) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'new_chart', {
        id: id
      });
    if (gtag)
      gtag('event', `${number}_charts_created`, {
        id: id
      });
  }
  posthog.capture('new_chart');
  posthog.capture(`${number}_charts_created`);
};
export const firstChartEvent = (id) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'first_chart', {
        id: id
      });
    const sendinblue = window.sendinblue;
    if (sendinblue) sendinblue.track('first_chart');
  }
};
export const editChartEvent = (id) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'edit_chart', {
        id: id
      });
  }
  posthog.capture('edit_chart');
};

export const NewTrackerEvent = (id, type, subscribed) => {
  if (window) {
    const gtag = window.gtag;
    if (gtag)
      gtag('event', 'new_tracker', {
        id: id,
        type: type,
        subscribed: subscribed
      });
  }
  posthog.capture('new_tracker');
};
