import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
);

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    console.log(error.message);
    throw error;
  }

  return data || [];
};

export const updateUserName = async (user, name) => {
  await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id);
};




export const priceIDToPlan = {
  'price_1JcA4lIaNdsNA3GSB2BE1dgJ' : 'Hobby',
  'price_1JcA4lIaNdsNA3GSLeKnlMEM' : 'Hobby',
  'price_1JcriDIaNdsNA3GShKXVDtN9' : 'Hobby',
  'price_1Jc98DIaNdsNA3GSWKrRAO4t' : 'Hobby',
  'price_1JcA6eIaNdsNA3GSxEVldPlj' : 'Pro',
  'price_1JcA6eIaNdsNA3GSi4sDhQSg' : 'Pro',
  'price_1JcrjMIaNdsNA3GSDIqqGsvi' : 'Pro',
  'price_1JcrjMIaNdsNA3GSXmsB247I' : 'Pro',
}
export const priceIDToPrice = {
  'price_1JcA4lIaNdsNA3GSB2BE1dgJ' : 5.99,
  'price_1JcA4lIaNdsNA3GSLeKnlMEM' : 4.99,
  'price_1JcriDIaNdsNA3GShKXVDtN9' : 5.99,
  'price_1Jc98DIaNdsNA3GSWKrRAO4t' : 4.99,
  'price_1JcA6eIaNdsNA3GSxEVldPlj' : 11.99,
  'price_1JcA6eIaNdsNA3GSi4sDhQSg' : 9.99,
  'price_1JcrjMIaNdsNA3GSDIqqGsvi' : 11.99,
  'price_1JcrjMIaNdsNA3GSXmsB247I' : 9.99,
}


export const hasReachedLimit = (charts, price_id) => {
  console.log('limit',charts, price_id)
  if (!price_id) return charts?.length >= 2 || false
  else {
    const plan = priceIDToPlan[price_id]
    if (plan === 'Hobby') {
      return charts?.length >= 10 || false
    } else if (plan === 'Pro') {
      return false
    }
  }  
}

export const getImages = async () => {
  const { data, error } = await supabase
    .from('images')
    .select('*')

  if (error) {
    console.log(error.message);
    throw error;
  }

  return data || [];
};
export const getImagesWithIDs = async (IDs) => {
  console.log('getImagewith ids', IDs)
  const { data, error } = await supabase
    .from('images')
    .select('*')
    .in('id', [...IDs] )

  if (error) {
    console.log('s ',error.message);
    throw error;
  }

  return data || [];
};